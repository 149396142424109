<template>
  <div class="dashboard">
    <div class="px-5 py-4 h-100">
      <div class="content-grid">

        <div class="header-div">
          <div>
            <input class="form-control" type="search" placeholder="搜尋(輸入報名ID、姓名或裝置ID)" v-model="searchInput"/>
          </div>
        </div>

        <div class="table-div">
          <ul>
            <li class="header">
              <div class="regId">報名ID</div>
              <div class="regName">姓名</div>
              <div class="regDeviceId">裝置ID</div>
            </li>

            <li v-for="(item) in sortedList" :key="item.serial" @click="selectUserSerial(item.serial)" :class="{'selected': item.serial === selectingUserSerial}">
              <div class="regId">{{ item.UserID }}</div>
              <div class="regName">{{ item.UserName }}</div>
              <div class="regDeviceId">{{ item.UserDeviceId }}</div>
            </li>
          </ul>
        </div>

        <div class="side-panel">
          <h3>{{ sideTitle }}</h3>

          <div class="switch-btn" v-if="isCreating">
            <a href="#" :class="{'active': creatingType === 0}" @click.prevent="creatingType = 0">單人</a>
            <a href="#" :class="{'active': creatingType === 1}" @click.prevent="creatingType = 1">團體</a>
          </div>

          <div v-if="creatingType === 0">
            <div class="input-container">
              <div class="input-div">
                <label>裝置ID</label>
                <input class="form-control" v-model="userModel.UserDeviceId" />
              </div>

              <div class="input-div">
                <label>報名ID</label>
                <input class="form-control" v-model="userModel.UserID" />
              </div>

              <div class="input-div">
                <label>選手姓名</label>
                <input class="form-control" v-model="userModel.UserName" />
              </div>
            </div>
            

            <div class="row mt-3 justify-content-center">
              <div class="col-4" v-if="isEditing">
                <button class="btn btn-grey" @click="cancelClicked">取消</button>
              </div>

              <div class="col-8" v-if="isEditing">
                <button class="btn btn-orange" @click="updateClicked">儲存</button>
              </div>

              <div class="col-8" v-if="isCreating">
                <button class="btn btn-orange" @click="createClicked">新增</button>
              </div>
            </div>
          </div>
          
          <div v-if="creatingType === 1">
            <div>
              <div class="upload-area">
                <input type="file" class="d-none" ref="inputCSVFile" accept=".csv" @change="fileSelected" />
                
                <div class="upload-btn" @click="uploadClicked" v-if="!hasBatchUser">
                  <img :src="require('@/assets/images/icons/upload.png')"/>
                  <p>
                    上傳檔案<br/>
                    <small>(請上傳UTF8格式的檔案)</small>
                  </p>
                </div>

                <div class="batch-panel" v-if="hasBatchUser">
                  <p>從文件中載入了 {{ batchUploadUsers.length }} 位參賽者資料。</p>
                  <div class="btns">
                    <button class="btn-cancel" @click="batchCancel">取消</button>
                    <button class="btn-bind" @click="batchUpload">綁定</button>
                  </div>
                </div>
              </div>

              <div class="sample-div">
                <a href="/sample.csv" download>下載範例CSV檔</a>
              </div>
            </div>
          </div>

          <div class="trash-btn" v-if="isEditing" @click="deleteClicked">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_312_100)">
                <path d="M15.471 1.60438H12.2355L11.311 0.679932H6.68881L5.76436 1.60438H2.52881V3.45326H15.471M3.45325 15.471C3.45325 15.9614 3.64805 16.4317 3.99478 16.7784C4.34151 17.1251 4.81179 17.3199 5.30214 17.3199H12.6977C13.1881 17.3199 13.6583 17.1251 14.0051 16.7784C14.3518 16.4317 14.5466 15.9614 14.5466 15.471V4.37771H3.45325V15.471Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_312_100">
                  <rect width="16.64" height="16.64" fill="white" transform="translate(0.679932 0.679932)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import utf8 from 'utf8';

// @ is an alias to /src

export default {
  name: 'Dashboard',
  data() {
    return {
      selectingUserSerial: -1,
      creatingType: 0,  // 0: single, 1: group
      searchInput: '',
      list: [],
      userModel: {
        UserName: '',
        UserID: '',
        UserDeviceId: '',
      },
      batchUploadUsers: [],
    };
  },
  computed: {
    sideTitle() {
      return this.isCreating? '新增選手資訊': '編輯選手資訊';
    },
    isCreating() {
      return this.selectingUserSerial === -1;
    },
    isEditing() {
      return this.selectingUserSerial !== -1;
    },
    filteredList() {
      const l = [... this.list];
      const query = this.searchInput;
      if (query.trim().length === 0) {
        return l;
      }      
      const res = l.filter((item) => {
        if (item.UserName.indexOf(query) !== -1) {
          return true;
        }
        if (item.UserID.indexOf(query) !== -1) {
          return true;
        }
        if (item.UserDeviceId.indexOf(query) !== -1) {
          return true;
        }
        return false;
      });
      return res;
    },
    sortedList() {
      const l = [... this.filteredList];
      const sort = l.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      });
      return sort;
    },
    hasBatchUser() {
      return this.batchUploadUsers.length > 0;
    }
  },
  components: {
  },
  async mounted() {
    // axios.get('/api/deviceRecord/device/00005813d35ed928').then(d => console.log(d));
    await this.readList();
  },
  methods: {
    clearUser() {
      this.userModel.UserName = '';
      this.userModel.UserID = '';
      this.userModel.UserDeviceId = '';
    },
    cancelClicked() {
      this.clearUser();
      this.selectingUserSerial = -1;
    },
    parseDataObject() {
      const createObj = this.userModel;
      if (createObj.UserName.trim().length === 0) {
        throw new Error('請輸入參賽者姓名');
      }
      if (createObj.UserID.trim().length === 0) {
        throw new Error('請輸入參賽者ID');
      }
      const realCreateObj = {
        UserName: createObj.UserName.trim(),
        UserID: createObj.UserID.trim(),
        UserDeviceId: createObj.UserDeviceId.trim(),
      };
      if (this.selectingUserSerial !== -1) {
        realCreateObj.serial = this.selectingUserSerial;
      }
      return realCreateObj;
    },
    async readList() {
      const BUSYNAME = 'READUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const users = await this.$store.dispatch('api/readUserListPromise');
        this.list.splice(0, this.list.length);
        for (const u of users) {
          this.list.push(u);
        }
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async createClicked() {
      const BUSYNAME = 'CREATEUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const realCreateObj = this.parseDataObject();
        const user = await this.$store.dispatch('api/createUserPromise', realCreateObj);
        this.list.push(user);
        this.clearUser();
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async updateClicked() {
      const BUSYNAME = 'UPDATEUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const realCreateObj = this.parseDataObject();
        const user = await this.$store.dispatch('api/updateUserPromise', realCreateObj);
        for (let i=0;i<this.list.length;i++) {
          if (this.list[i].serial === user.serial) {
            this.$set(this.list, i, user);
            break;
          }
        }
        this.clearUser();
        this.selectingUserSerial = -1;
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async deleteClicked() {
      const r = confirm('確定刪除這個使用者嗎?');
      if (!r) {
        return;
      }
      const BUSYNAME = 'DELETEUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        await this.$store.dispatch('api/deleteUserPromise', this.selectingUserSerial);
        for (let i=0;i<this.list.length;i++) {
          if (this.list[i].serial === this.selectingUserSerial) {
            this.list.splice(i, 1);
            break;
          }
        }
        this.clearUser();
        this.selectingUserSerial = -1;
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    selectUserSerial(serial) {
      const f = this.list.filter((item) => {
        return item.serial === serial;
      });
      if (f.length === 1) {
        const obj = f[0];
        this.userModel.UserName = obj.UserName;
        this.userModel.UserID = obj.UserID;
        this.userModel.UserDeviceId = obj.UserDeviceId;
        this.selectingUserSerial = serial;
        this.creatingType = 0;
      }
    },
    uploadClicked() {
      this.$refs['inputCSVFile'].click();
    },
    parseBatchUsers(csvText) {
      try {
        // console.log(utf8.decode(csvText));
        // console.log(csvText);
        // console.log(atob(csvText.split(',')[1]));
        this.batchUploadUsers.splice(0, this.batchUploadUsers.length);
        const lines = csvText.split('\n');
        lines.shift();
        for (const line of lines) {
          if (line.trim().length > 0) {
            const cols = line.split(',');
            if (cols.length !== 3) {
              throw new Error('文件內格式錯誤:\n' + line);
            }
            const tempUser = {
              UserName: cols[1],
              UserID: cols[0],
              UserDeviceId: cols[2],
            };
            this.batchUploadUsers.push(tempUser);
          }
        }
      } catch (error) {
        console.error(error);
        alert(error.toString());
        this.batchUploadUsers.splice(0, this.batchUploadUsers.length);
      }
    },
    fileSelected(f) {
      const inputEle = f.target;
      const file = inputEle.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        // console.log(event);
        const csvdata = event.target.result;
        this.parseBatchUsers(csvdata);
      };
      reader.readAsText(file);
      // reader.readAsDataURL(file);
      
      f.target.value = '';
    },
    batchCancel() {
      this.batchUploadUsers.splice(0, this.batchUploadUsers.length);
    },
    async batchUpload() {
      const BUSYNAME = 'CREATEUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        for (const realCreateObj of this.batchUploadUsers) {
          const user = await this.$store.dispatch('api/createUserPromise', realCreateObj);
          this.list.push(user);
        }
        this.batchUploadUsers.splice(0, this.batchUploadUsers.length);
        alert('團體綁定成功!');
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-button {
    background: transparent;
    border-radius: 4px;
  }

  *::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid slategrey;
  }

  *::-webkit-scrollbar-track {
    box-shadow: transparent;
  }

  .dashboard {
    height: 100%;
  }

  .content-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: "header header"
      "main side";
    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .header-div {
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    input {
      border-radius: 999px;
      padding: .5rem 1.5rem;
      min-width: 600px;
    }
  }

  .side-panel {
    position: relative;
    grid-area: side;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 1rem;
    padding: 1.5rem 1.3rem;
    min-width: 450px;
    @media screen and (max-width: 1200px) {
      min-width: inherit;
    }
    h3 {
      color: var(--dark-green);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 2.25rem */
      letter-spacing: 0.15rem;
      margin-bottom: 1rem;
    }

    .trash-btn {
      position: absolute;
      top: 2.5rem;
      right: 1.3rem;
      background-color: #444444;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: #999999;
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      row-gap: .75rem;
      .input-div {
        // margin-bottom: 2rem;
        label {
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 1.5rem */
          letter-spacing: 0.1rem;
          color: #444444;
          margin-bottom: .25rem;
          padding-left: .5em;
        }

        input {
          background-color: var(--light-green);
          color: var(--dark-green);
          margin-bottom: 1rem;
        }
      }
    }
    

    .btn {
      border-radius: 999px;
      width: 100%;
      color: #fff;
      font-size: 2rem;
      &.btn-orange {
        background-color: var(--light-orange);
        &:hover {
          background-color: var(--dark-orange);
        }
      }

      &.btn-grey {
        background-color: #999999;
        &:hover {
          background-color: #444;
        }
      }
    }
  }

  .switch-btn {
    display: flex;
    column-gap: .63rem;
    background-color: var(--light-green);
    border-radius: 999px;
    padding: .25rem;
    margin-bottom: 2rem;
    a {
      flex: 1 1;
      display: block;
      padding: .31rem;
      text-align: center;
      color: #444;
      border-radius: 999px;
      &.active {
        color: #fff;
        background-color: var(--main-green);
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  .upload-area {
    border-radius: 0.5rem;
    border: 1px dashed #C5F3EC;
    background: var(--light-green);
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .upload-btn {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: .75rem;
      cursor: pointer;
      padding: 2rem;
      img {
        display: block;
        width: 3.75rem;
      }
      p {
        color: #616261;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.125rem;
        margin-bottom: 0;
      }
    }

    .batch-panel {
      width: 100%;
      padding: 1rem 2rem;
      .btns {
        display: flex;
        column-gap: 1rem;
        >button {
          padding: .25rem 1.5rem;
          border-radius: 999px;
          color: #fff;
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 3rem */
          letter-spacing: 0.2rem;
          flex: 1 1;
          text-align: center;
          background-color: #444;
          border: none;
          transition: background-color .4s ease;
          &.btn-cancel {
            background-color: #999;
            &:hover {
              background-color: #444;
            }
          }

          &.btn-bind {
            background-color: var(--main-orange);
            &:hover {
              background-color: var(--dark-orange);
            }
          }
        }
      }
    }
  }

  .sample-div {
    margin-top: .5rem;
    a {
      color: var(--main-green);
      text-decoration: underline;
    }
  }

  .table-div {
    grid-area: main;
    position: relative;
    padding-right: 1rem;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: .5rem 2.5rem;
        margin: 0;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 999px;
        color: var(--dark-green);
        &.header {
          margin-top: 0;
          background-color: #B7DED8;
          color: var(--dark-green);
          font-weight: 800;
          position: sticky;
          top: 0;
        }
        &:not(.header) {
          cursor: pointer;
        }
        .regId {
          flex: 0 0 20%;
        }
        .regName {
          flex: 0 0 30%;
        }
        .regDeviceId {
          flex: 1 1;
        }
        &.selected {
          background-color: var(--dark-green);
          color: #fff;
        }
      }
    }
  }

</style>
